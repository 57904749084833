'use strict';

var updateTimer = function () {
    for (var i = 0; i < $('.timer').length; i += 1) {
        var thisTimer = $('.timer').eq(i);
        var timestamp = thisTimer.data('timestamp');
        var date = new Date(timestamp);
        date.setYear(new Date().getFullYear());
        var timer = (date.getTime() - new Date()) / 1000;
        var minutes;
        var seconds;

        timer -= 1;
        if (timer < 0) {
            thisTimer.closest('div').find('.invalid-expired').show();
            var msg = thisTimer.closest('div').find('.invalid-expired').data('error');
            thisTimer.closest('.expiry__time').hide();
            thisTimer.closest('div').find('.invalid-expired').addClass('alert alert-danger')
                .find('span')
                .html(msg);
            $('.checkout-btn').addClass('disabled');
        } else {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            if (thisTimer.length > 0) {
                thisTimer[0].textContent = minutes + ':' + seconds;
                thisTimer.closest('div').find('.invalid-expired').hide();
            }
        }
    }
};

module.exports = {
    startTimer: function () {
        updateTimer();
        setInterval(updateTimer, 1000);
    }
};
