'use strict';

const $tabControls = $('[data-tab-control]');
const $tabControlList = $tabControls.find('[data-tab-control-target]');
const $panelControlList = $tabControls.find('[data-tab-control-id]');

const ACTIVE_TAB_CLASS = 'passenger__block-tab--isActive';
const ACTIVE_PANEL_CLASS = 'passenger__panel--active';

function changeActiveTab($currentTab) {
    $tabControlList.parent().removeClass(ACTIVE_TAB_CLASS);
    $currentTab.parent().addClass(ACTIVE_TAB_CLASS);
}

function changeActivePanel($currentPanel) {
    $panelControlList.removeClass(ACTIVE_PANEL_CLASS);
    $currentPanel.addClass(ACTIVE_PANEL_CLASS);
}

function loadEvents() {
    $tabControls.on('click', '[data-tab-control-target]', function () {
        const $currentTab = $(this);
        const targetId = $currentTab.attr('data-tab-control-target');
        const $targetPanel = $('[data-tab-control-id="' + targetId + '"]');

        changeActiveTab($currentTab);
        changeActivePanel($targetPanel);
    });

    $('[data-tab-control-target="originTravel"]').click();
}

function mount() {
    if ($tabControls.length <= 0) {
        return;
    }

    loadEvents();
}

module.exports = {
    start: function () {
        mount();
    }
};
